import React from "react";

type AreaWithHighlightProps = {
  coords: string;
  onClick: (key: any) => void;
  title: string;
};

const AreaWithHighlight: React.FC<AreaWithHighlightProps> = ({
  coords,
  onClick,
  title,
}) => {
  const [x, y, radius] = coords.split(",").map(Number);

  return (
    <div
      className="absolute flex items-center justify-center"
      style={{
        left: `${x}%`,
        top: `${y}%`,
        width: `${radius * 2}px`,
        height: `${radius * 2}px`,
        borderRadius: "50%", // This creates the circular shape
        backgroundColor: "rgb(255, 197, 16, 0.75)",
        borderBlockColor: "white",
        borderColor: "rgb(255 255 255)",
        borderStyle: "solid",
        borderWidth: "2px",
      }}
    >
      <a
        onClick={onClick}
        className="flex items-center justify-center absolute inset-0"
        style={{ borderRadius: "50%" }} // This ensures the clickable area is also circular
      >
        {/* The title text */}
        <span className="absolute font-bold right-5 text-center w-20 whitespace-nowrap px-2 py-1 bg-orange text-black text-sm rounded-full opacity-90 border-2 border-white capitalize">
          {title}
        </span>
      </a>
    </div>
  );
};

export default AreaWithHighlight;
