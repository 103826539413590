import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import data from "../contentSikagard.json";
import products from "../productSikagard.json";
import { ReplacedProduct } from "../component/ReplacedProduct";
import { Choices } from "../component/Choices";
import { Product } from "../component/Product";
import { AutreSupport } from "../component/AutreSupport";
import PageNotFound from "../component/404";
import { FirstChoiceSikagard } from "../component/FirstChoiceSikagard";
import StickyContact from "../component/StickyContact";
import { ClickableImg } from "../component/ClickableImg";

export default function WelcomeSikagard() {
  let nav = useLocation();
  const update = useNavigate();

  const handleNavigation = (key: any) => {
    update(key, { relative: "path" });
  };

  const [state, setState] = useState<any>(data);
  const [productsData, setProducts] = useState<any>(products);

  const [isReplacedSelected, setIsReplacedSelected] = useState<boolean>(false);
  useEffect(() => {
    setIsReplacedSelected(false);
    console.log("NAV", nav);
    let path = nav.pathname.split("/");
    console.log(path);

    path.shift();
    console.log(path);
    let preciseData: any = data;

    for (let i in path) {
      console.log(path[i]);
      if (
        (preciseData.zoneChoice && preciseData.zoneChoice[path[i]]) ||
        (preciseData.firstChoice && preciseData.firstChoice[path[i]]) ||
        (preciseData.choices && preciseData.choices[path[i]]) ||
        (preciseData.product && preciseData.product[path[i]]) ||
        (preciseData.replacedProduct && preciseData.replacedProduct[path[i]])
      ) {
        if (preciseData.zoneChoice && preciseData.zoneChoice[path[i]]) {
          preciseData = preciseData.zoneChoice[path[i]];
        } else if (
          preciseData.firstChoice &&
          preciseData.firstChoice[path[i]]
        ) {
          preciseData = preciseData.firstChoice[path[i]];
        } else if (preciseData.choices && preciseData.choices[path[i]]) {
          preciseData = preciseData.choices[path[i]];
        } else if (preciseData.product && preciseData.product[path[i]]) {
          preciseData = preciseData.product[path[i]];
        } else if (
          preciseData.replacedProduct &&
          preciseData.replacedProduct[path[i]]
        ) {
          preciseData = preciseData.replacedProduct[path[i]];
        }
        console.log("DATA PRECISED", preciseData);
      } else {
        console.log("PATH NOT FOUND");
      }
    }

    setState(() => preciseData);
  }, [nav]);

  if (state.zoneChoice) {
    return (
      <div className="flex flex-grow">
        <StickyContact />
        <div className="items-center flex flex-col mt-6 h-full flex-grow-1">
          <h1 className="text-xl font-bold">{state.title}</h1>
          <p className="text-lg font-regular mt-5 mb-5">{state.subtitle}</p>
          <ClickableImg handleNavigation={handleNavigation} />
        </div>
      </div>
    );
  }

  if (state.firstChoice) {
    return (
      <FirstChoiceSikagard
        state={state}
        update={update}
        handleNavigation={handleNavigation}
      />
    );
  }

  if (state.choices) {
    return (
      <Choices
        state={state}
        handleNavigation={handleNavigation}
        update={update}
      />
    );
  }

  if (state.product && (!isReplacedSelected || !state.replacedProduct)) {
    return (
      <Product
        state={state}
        productsData={productsData}
        update={update}
        setIsReplacedSelected={setIsReplacedSelected}
      />
    );
  }

  if (state.replacedProduct && isReplacedSelected) {
    return (
      <ReplacedProduct
        state={state}
        productsData={productsData}
        update={update}
        setIsReplacedSelected={setIsReplacedSelected}
      />
    );
  }
  if (!state.product) {
    return <AutreSupport />;
  }
  return <PageNotFound />;
}
