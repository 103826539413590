import StickyContact from "./StickyContact";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";

interface ChoicesProps {
  state: any;
  update: (path: any) => void;
  handleNavigation: (key: any) => void;
}

export const Choices: React.FC<ChoicesProps> = ({
  state,
  update,
  handleNavigation,
}) => {
  let ListChoice = Object.keys(state.choices).sort();

  return (
    <div className=" flex flex-wrap mx-6% w-90%">
      <StickyContact />
      <div className="flex flex-wrap ">
        <div className="items-center flex mt-10%">
          <button onClick={() => update(-1)}>
            <IoIosArrowBack className="w-10 h-12" />
          </button>
          <div>
            <h1 className="text-2xl font-bold">{state.title}</h1>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-5">
          {ListChoice.map((key: any) => {
            {
              console.log(state.choices[key].img);
            }
            return (

                <button key={key} onClick={() => handleNavigation(key)} className="relative my-2 w-40 h-12 rounded">
                  {state.choices[key].img && (
                    <img
                      src={require(`../img/imgMateriau/${state.choices[key].img}`)}
                      alt=""
                      className="w-40 h-12 rounded brightness-75"
                    />
                  )}
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                  <h4 className="font-bold text-sm text-white">
                    {state.choices[key].placeholder}
                  </h4>
                  </div>
                </button>

            );
          })}
        </div>
      </div>
    </div>
  );
};
