import React from "react";
import AreaWithHighlight from "./AreaWithHighlight";

interface ClickableImgProps {
  handleNavigation: (key: any) => void;
}

export const ClickableImg: React.FC<ClickableImgProps> = ({
  handleNavigation,
}) => {
  return (
    <div className="relative">
      <img
        src={require("../img/image-vert.jpeg")}
        useMap="#imageMap"
        alt="Interactive Map"
        className="w-full"
      />

      <map name="imageMap">
        <AreaWithHighlight
          title="Toiture"
          onClick={() => handleNavigation("toiture")}
          // coords="250,135,20"
          coords="60,20,20"
        />
        <AreaWithHighlight
          title="Façade"
          onClick={() => handleNavigation("facade")}
          // coords="320,250,20"
          coords="80,45,20"

        />
        <AreaWithHighlight
          title="Sol"
          onClick={() => handleNavigation("sol")}
          // coords="120,400,20"
          coords="20,70,20"

        />
      </map>
    </div>
  );
};
