import StickyContact from "./StickyContact";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";

interface FirstChoiceProps {
  state: any;
  update: (path: any) => void;
  handleNavigation: (key: any) => void;
}

export const FirstChoiceSikagard: React.FC<FirstChoiceProps> = ({
  state,
  update,
  handleNavigation,
}) => {
  let ListChoice = Object.keys(state.firstChoice);
  return (
    <div className="overflow-auto w-85% flex justify-center mx-7%">
      <StickyContact />
      <div className="items-center flex flex-col mt-6">
        <div className="items-center flex mt-10%">
          <button onClick={() => update(-1)}>
            <IoIosArrowBack className="w-6 h-12" />
          </button>
          <div>
            <h1 className="text-l font-bold">{state.title}</h1>
          </div>
        </div>
        {ListChoice.map((key: any) => (
          <div className="relative" key={key}>
            <button onClick={() => handleNavigation(key)}>
              <img
                src={require(`../img/imgAccueil/${state.firstChoice[key]?.img}`)}
                alt=""
                className="w-64 h-64 rounded-2xl my-7 object-cover object-center brightness-75"
              />
              <h1 className=" absolute top-45% left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-5xl text-white">
                {state.firstChoice[key].placeholder}
              </h1>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
