import React, { cloneElement, useEffect } from "react";
import "./App.css";

import Footer from "./component/Footer";

import Header from "./component/Header";
import { Routes, Route, useNavigate } from "react-router-dom";

import Welcome from "./views/Welcome";
import Mentions from "./views/Mentions";
import Politique from "./views/Politique";
import WelcomeSikagard from "./views/WelcomeSikagard";

export default function App() {
  useEffect(() => {
    console.log("NAV1111111", window.location.host);
  }, []);
  if (
    window.location.host === "localhost:3000" ||
    window.location.host === "sika.juniorisep.com"
  ) {
    return (
      <body id="top" className="flex flex-col min-h-screen">
        <Header />

        <main className="flex-grow">
          <Routes>
            <Route path="/*" element={<Welcome />} />
            <Route path="/mentions" element={<Mentions />} />
            <Route path="/politique" element={<Politique />} />
          </Routes>
        </main>
        <Footer />
      </body>
    );
  }
  return (
    <body id="top" className="flex flex-col min-h-screen">
      <Header />

      <main className="flex-grow">
        <Routes>
          <Route path="/*" element={<WelcomeSikagard />} />
          <Route path="/mentions" element={<Mentions />} />
          <Route path="/politique" element={<Politique />} />
        </Routes>
      </main>
      <Footer />
    </body>
  );
}
